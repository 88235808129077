html,
body {
  background-color: #000000; /* Change this to your preferred background color */
  -webkit-overflow-scrolling: touch;
}

.homeContainer {
  position: relative;
  height: 70vh;
  margin: 0;
  overflow: hidden;
  z-index: 0; /* Establish stacking context */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homeContainer::before {
  content: "";
  position: absolute;
  top: -10px; /* Extend beyond the container to counteract the blur "growth" */
  left: -10px;
  right: -10px;
  bottom: -10px;
  background-image: url("/src/assets/concert-background-t7rzcc096yvm7l7b.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(8px) brightness(0.3);
  z-index: -1; /* Ensure it stays behind the content */
}

.App-logo {
  margin-bottom: 60px;
  margin-top: 70px;
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo:hover {
    animation: shake 1.5s ease-in-out infinite;
  }
}

.homeMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 35vw;
}

@media (max-width: 1200px) {
  .homeMain {
    width: 50vw;
  }
}

@media (max-width: 960px) {
  .homeMain {
    width: 70vw;
  }
}

h3 {
  font-weight: 300;
  font-size: calc(10px + 2vmin);
  color: rgb(230, 229, 229);
  text-align: center;
}

.homePageButton {
  background: #ef1c09;
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.homePageButton:hover {
  background: #d41908;
}

.homePageButton:active {
  background: #c21607;
}

@keyframes shake {
  0%,
  100% {
    transform: translate(0, 0);
  }
  5% {
    transform: translate(8px, -4px);
  }
  10% {
    transform: translate(-6px, 3px);
  }
  15% {
    transform: translate(7px, -2px);
  }
  20% {
    transform: translate(-5px, 5px);
  }
  25% {
    transform: translate(6px, -3px);
  }
  30% {
    transform: translate(-4px, 2px);
  }
  35% {
    transform: translate(5px, -1px);
  }
  40%,
  60% {
    transform: translate(-3px, 0);
  }
  45%,
  65% {
    transform: translate(3px, 0);
  }
  50% {
    transform: translate(-2px, 2px);
  }
  55% {
    transform: translate(2px, -2px);
  }
  70% {
    transform: translate(-1px, 1px);
  }
  75% {
    transform: translate(1px, -1px);
  }
  80% {
    transform: translate(-1px, -1px);
  }
  85% {
    transform: translate(1px, 1px);
  }
  90% {
    transform: translate(1px, -1px);
  }
  95% {
    transform: translate(-1px, 1px);
  }
}
