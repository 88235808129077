.pagePadding {
  padding: 40px 240px;
}

@media (max-width: 1200px) {
  .pagePadding {
    padding: 40px 160px;
  }
}

@media (max-width: 960px) {
  .pagePadding {
    padding: 40px 100px;
  }
}

@media (max-width: 600px) {
  .pagePadding {
    padding: 40px 40px;
  }
}
