.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  min-height: 60px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
}

.homeButton {
  background: none;
  color: white;
  text-decoration: underline;
  text-decoration-color: #ef1c09; /* Color of the underline */
  text-decoration-thickness: 2px; /* Thickness of the underline */
  text-underline-offset: 4px; /* Offset of the underline from the text */
  font-size: 16px;
  font-weight: 600;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  height: 100%;
}

.homeButton:hover {
  text-decoration-color: #d41908; /* Color of the underline */
  color: rgb(226, 226, 226);
}

.homeButton:active {
  text-decoration-color: #c21607; /* Color of the underline */
  color: rgb(211, 211, 211);
}

.menuContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 40px;
  padding: 4px;
}

.hamburgerButton {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 100%;
  z-index: 1;
}

.hamburgerButton:hover .line {
  background-color: rgb(226, 226, 226);
}

.hamburgerButton .line {
  background-color: white;
  height: 2px;
  width: 24px;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.line::before,
.line::after {
  content: "";
  position: absolute;
  border-radius: 50%;
}

/* Resetting the general styles for ::before and ::after to not interfere */
.line::before,
.line::after {
  width: 0;
  height: 0;
  background-color: white;
  bottom: auto;
  left: auto;
  transform: none;
}

/* Adding oval dots to the first and last line */
.line:first-child::before {
  content: "";
  position: absolute;
  width: 6px; /* Oval width */
  height: 3px; /* Oval height */
  background-color: #ffffff; /* Dot color, change as needed */
  border-radius: 50%;
  left: -1px;
  top: 50%;
  transform: translateY(-50%);
}

.line:last-child::before {
  content: "";
  position: absolute;
  width: 6px; /* Oval width */
  height: 3px; /* Oval height */
  background-color: #ffffff; /* Dot color, change as needed */
  border-radius: 50%;
  right: -1px;
  top: 50%;
  transform: translateY(-50%);
}

/* Ensure .line is positioned relatively for absolute positioning within */
.line {
  position: relative;
  /* Other styles remain unchanged */
}

.hamburgerButton.open .line1 {
  transform: rotate(405deg) translate(5.5px, 5.5px);
  transform-origin: center;
}

.hamburgerButton.open .line2 {
  opacity: 0;
}

.hamburgerButton.open .line3 {
  transform: rotate(-405deg) translate(5px, -5px);
  transform-origin: center;
}

.dropdownMenu {
  display: block;
  position: absolute;
  top: 80px;
  background-color: black;
  min-width: 160px;
  z-index: 1;
  border: 2px solid rgb(97, 97, 97);
  border-radius: 11px;
  animation: slideIn 0.2s ease-out;
  overflow: hidden;
}

.dropdownMenu button {
  background: black;
  width: 100%;
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  cursor: pointer;
  font-size: 18px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  text-align: left;
  display: block;
  line-height: normal;
  margin: 0;
  outline: none;
  border: none;
  text-decoration: none;
  display: block;
}

.dropdownMenu button:hover {
  background-color: #252525;
}

.dropdownMenu button:active {
  background-color: #1a1a1a;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
