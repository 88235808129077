.storyContainer {
  background: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  color: white;
}

.storyMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  max-width: 400px;
}

p {
  margin: 0;
  margin-top: 20px;
}
