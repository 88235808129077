.constructionContainer {
  position: relative;
  height: 70vh;
  margin: 0;
  overflow: hidden; /* Keeps overflow caused by the blur effect hidden */
  z-index: 0; /* Establish stacking context */
}

.constructionContainer::before {
  content: "";
  position: absolute;
  top: -10px; /* Extend beyond the container to counteract the blur "growth" */
  left: -10px;
  right: -10px;
  bottom: -10px;
  background-image: url("/src/assets/concert-background-t7rzcc096yvm7l7b.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(8px) brightness(0.3);
  z-index: -1; /* Ensure it stays behind the content */
}

.constructionMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  max-width: 100%;
  height: calc(100vh - 200px);
  color: white;
}
